<template>
  <Dialog
    v-model:visible="mostrarModal"
    :style="{ width: '450px' }"
    header="CREAR NUEVA FACTURA"
    :modal="true"
    class="p-fluid"
    @hide="ocultarDialog"
  >
    <div class="p-fluid formgrid grid">
      <div
        class="field col-12 md:col-12 card"
        style="background-color: aquamarine"
      >
        <p>
          Para emitir una factura debe ingresar el NIT y
          <strong>BUSCAR</strong>, si el cliente no existe debe
          <strong>CREAR</strong> uno nuevo y BUSCAR nuevamente.
        </p>
      </div>
      <div class="field col-12 md:col-3">
        <p><strong>ID VENTA: </strong>{{ data_factura.id }}</p>
      </div>
      <div class="field col-12 md:col-5">
        <p><strong>PACIENTE: </strong>{{ data_factura.nombre_cliente }}</p>
      </div>
      <div class="field col-12 md:col-4">
        <p><strong>TOTAL VENTA:</strong>{{ data_factura.total_cancelar }}</p>
      </div>
      <div class="field col-12 md:col-4">
        <label for="nit"
          ><strong>NIT FACTURA: </strong>
          <span class="p-invalid">*</span></label
        >
        <div class="p-inputgroup">
          <InputText
            id="nit"
            v-model="data_factura.nit"
            required="true"
            autofocus
            @keyup.enter="buscarClientePorNit"
          />
          <small class="p-invalid" v-if="errors.nit">{{ errors.nit[0] }}</small>
          <Button
            icon="pi pi-search"
            class="p-button-rounded p-button-info p-button-outlined ml-1"
            v-tooltip.top="'Buscar NIT'"
            @click="buscarClientePorNit"
          />
          <!-- <Button
            icon="pi pi-user"
            class="p-button-rounded p-button-info p-button-outlined ml-1"
            v-tooltip.top="'Nuevo Paciente'"
            @click="activarCliente"
          /> -->
          <Button
            icon="pi pi-user"
            class="p-button-rounded p-button-info p-button-outlined ml-1"
            v-tooltip.top="'Nuevo Paciente'"
            @click="activarClienteFactura"
          />
        </div>
      </div>

      <div class="field col-12 md:col-8">
        <label for="nombre_cliente"
          ><strong>RAZ&Oacute;N SOCIAL: </strong>
          <span class="p-invalid">*</span></label
        >
        <InputText
          id="razon_social"
          v-model="data_factura.razon_social"
          required="true"
          disabled
        />
        <small class="p-invalid" v-if="errors.razon_social">{{
          errors.razon_social[0]
        }}</small>
      </div>
    </div>
    <template #footer>
      <div class="field">
        <label class="flex md:justify-content-start"
          ><strong>CAMPOS REQUERIDOS: </strong>
          <span class="p-invalid">*</span></label
        >
      </div>
      <Button
        label="CANCELAR"
        icon="pi pi-times"
        class="p-button-success p-button-lg"
        @click="ocultarDialog"
      />
      <Button
        label="EMITIR FACTURA"
        icon="pi pi-send"
        class="p-button-danger p-button-lg"
        @click="guardarVentaFactura"
        :loading="enviado"
        :disabled="
          this.data_factura.nit.length < 5 ||
          this.data_factura.razon_social == null
        "
      />
    </template>
  </Dialog>
  <ClienteCreate
    :show="clienteModal"
    :cliente="cliente"
    @closeModal="cerrarModalCliente"
    @actualizarListado="buscarClientePorNit"
  >
  </ClienteCreate>
  <ClienteFactura
    :show="clienteFacturaModal"
    :cliente_factura="cliente_factura"
    @closeModal="cerrarModalClienteFactura"
    @actualizarListado="buscarClientePorNit"
  >
  </ClienteFactura>
</template>

<script>
import VentaService from "@/service/VentaService";
import ClienteService from "@/service/ClienteService";
import ClienteCreate from "@/module/clientes/ClienteCreate.vue";
import ClienteFactura from "@/module/clientes/ClienteFactura.vue";

export default {
  components: {
    ClienteCreate,
    ClienteFactura,
  },
  emits: ["closeModal", "actualizarListado"],

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    ventaFactura: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  ventaService: null,
  clienteService: null,
  data() {
    return {
      mostrarModal: this.show,
      data_factura: this.ventaFactura,
      errors: {},
      nit: 0,
      razon_social: null,
      enviado: false,
      clienteModal: false,
      cliente: { estado: { label: "Activo", value: 1 } },
      cliente_factura: { estado: { label: "Activo", value: 1 } },
      clienteFacturaModal: false,
    };
  },
  created() {
    this.ventaService = new VentaService();
    this.clienteService = new ClienteService();
  },
  methods: {
    activarCliente() {
      this.clienteModal = true;
      this.cliente = {
        estado: { label: "Activo", value: 1 },
      };
    },
    activarClienteFactura() {
      this.clienteFacturaModal = true;
      this.cliente_factura = {
        estado: { label: "Activo", value: 1 },
        ci_nit: this.data_factura.nit ?? "",
      };
    },
    cerrarModalClienteFactura() {
      this.clienteFacturaModal = false;
      this.cliente_factura = {};
    },
    cerrarModalCliente() {
      this.clienteModal = false;
      this.cliente = {};
    },
    buscarClientePorNit() {
      if (this.nit != null) {
        this.clienteService
          .buscarClientePorNit({ nit: this.data_factura.nit })

          .then((response) => {
            if (response.cliente != null) {
              this.$toast.add({
                severity: "success",
                summary: "Nit encontrado",
                detail: "Se cargaron los datos del cliente",
                life: 3000,
              });
              let nombre_cliente =
                response.cliente.nombre_cliente ?? response.cliente;
              this.data_factura.razon_social = nombre_cliente ?? "";
            } else {
              this.$toast.add({
                severity: "error",
                summary: "Cliente no encontrado",
                detail: "Registre los datos del cliente",
                life: 3000,
              });
              this.data_factura.razon_social = "";
              this.activarClienteFactura();
            }
          });
      }
    },
    guardarVentaFactura() {
      if (this.data_factura.nit.length < 5) {
        this.$toast.add({
          severity: "error",
          summary: "Factura",
          detail: "El NIT debe tener al menos 5 digitos",
          life: 3000,
        });
        return;
      }
      this.enviado = true;
      this.errors = {};
      let datos = this.data_factura;
      this.data_factura = {
        ...this.data_factura,
      };
      this.ventaService.crear_factura(datos).then((response) => {
        if (Object.prototype.hasOwnProperty.call(response, "errors")) {
          this.errors = response.errors;
          this.enviado = false;
        } else {
          this.errors = {};
          if (response.status == 400) {
            this.$toast.add({
              severity: "error",
              summary: "Factura",
              detail: response.message,
              life: 3000,
            });
            this.enviado = false;
          } else {
            this.$toast.add({
              severity: "success",
              summary: "Factura",
              detail: "Factura guardada correctamente",
              life: 3000,
            });
            this.$emit("actualizarListado");
            this.ocultarDialog();
            this.enviado = false;
            //IMPRIMO LA FACTURA
            this.imprimirVenta(this.data_factura.id);
          }
        }
      });
    },
    ocultarDialog() {
      this.$emit("closeModal");
    },
    imprimirVenta(id) {
      this.ventaService
        .imprimirVenta(id)
        .then(() => {})
        .catch((error) => console.log(error));
    },
  },
  watch: {
    show(value) {
      this.mostrarModal = value;
    },
    ventaFactura(value) {
      this.data_factura = value;
      this.data_factura.razon_social = null;
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-invalid {
  color: red;
}
</style>
